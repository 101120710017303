import React, { useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import { getSignupCallbackURL } from 'lib/uwp'
import {
  ContentfulComponentHeader,
  ContentfulLayout,
  ContentfulSEO,
} from 'types/contentful'
import * as Sentry from '@sentry/gatsby'

type DataProps = {
  contentfulPageLanding: {
    layout: ContentfulLayout
    header: ContentfulComponentHeader
    partnerSlug: string
    programSlug: string
    seo: ContentfulSEO
  }
}

const RedirectPage: React.FC<PageProps<DataProps>> = (props) => {
  const { partnerSlug, programSlug, layout, header, seo } =
    props.data.contentfulPageLanding

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const flowParam = urlParams.get('path')
    const signUpToken = urlParams.get('signUpToken')
    getSignupCallbackURL({
      partnerKey: partnerSlug,
      programKey: programSlug,
      pathParam: flowParam,
      signUpToken: signUpToken,
    })
      .then((url) => {
        window.location.href = url
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })
  }, [])

  return (
    <Layout layout={layout} header={header} seo={seo}>
      <div className="container">
        <h1>Redirecting...</h1>
      </div>
    </Layout>
  )
}

export default RedirectPage

export const pageQuery = graphql`
  query RedirectPageQuery($contentful_id: String!) {
    contentfulPageLanding(contentful_id: { eq: $contentful_id }) {
      contentful_id
      partnerSlug
      programSlug
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      seo {
        ...SeoFields
      }
    }
  }
`
